<template>
    <div class="container">
        <div class="faq_box">
            <div class="title">
                <div class="title_txt flex_center_start_box">
                    <img src="../../assets/img/detail/title_ic18.png" />
                    FREQUENTLY ASKED QUESTIONS
                </div>
            </div>
            <div class="faq_cont">
                <el-collapse accordion v-model="activeNames" @change="changeCollapse">
                    <el-collapse-item :class="item.power == true ? 'item_title' : 'item_title item_title1'" v-for="(item, index) in faqList" :disabled="item.disabled" :key="index" v-show="!item.status" :name="index + 1" :title="item.title">
                        <!-- 判断power需不需要上锁 -->

                        <template slot="title">
                            {{ item.title }}
                            <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="item.power == false"></i>
                        </template>
                        <div v-if="index == 0">
                            <div class="item_cont_title">Registered address:</div>
                            <div class="item_cont_txt" v-show="!showTranslate">{{ contObj.regStreet }} / {{ contObj.regAddress }} / {{ contObj.regCountry }}</div>
                            <div class="item_cont_txt" v-show="showTranslate">{{ contObj.regStreetCn }}/ {{ contObj.regAddressCn }} / {{ contObj.regCountryCn }}</div>
                            <div class="item_cont_title">Operating address:</div>
                            <div class="item_cont_txt" v-show="!showTranslate">{{ contObj.opeStreet }}/ {{ contObj.opeAddress }} / {{ contObj.opeCountry }}</div>
                            <div class="item_cont_txt" v-show="showTranslate">{{ contObj.opeStreetCn }}/ {{ contObj.opeAddressCn }} / {{ contObj.opeCountryCn }}</div>
                        </div>

                        <div v-else-if="index == 1">
                            <div class="item_cont_title">Phone number:</div>
                            <div class="item_cont_txt">{{ contObj.phone }}</div>
                            <div class="item_cont_title">Website:</div>
                            <div class="item_cont_txt">{{ contObj.website }}</div>
                            <div class="item_cont_title">Email:</div>
                            <div class="item_cont_txt">{{ contObj.email }}</div>
                            <button :disabled="contObj.website == 'n.a.'" class="btn_find" @click="contactInfo">Find more contacts</button>
                        </div>
                        <div v-else-if="index == 2">
                            <div class="item_cont_title">Registered business scope:</div>
                            <div class="item_cont_txt">{{ contObj.regBScope }}</div>
                            <div class="item_cont_title">Main business activities:</div>
                            <div class="item_cont_txt">{{ contObj.mainBAct }}</div>
                        </div>
                        <div v-else-if="index == 3">
                            <div class="item_cont_title">Industry sector:</div>
                            <div class="item_cont_txt">{{ contObj.sector }}</div>
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">SIC:</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.isicCode }}</div>
                            </div>
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">NAICS:</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.naics }}</div>
                            </div>
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">NACE:</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.nace }}</div>
                            </div>
                        </div>
                        <div v-else-if="index == 4"></div>
                        <div v-else-if="index == 5">
                            <div class="flex_center_between_box c_m_b" v-for="(item, index) in contObj.similarCompany" :key="index">
                                <div class="flex_center">
                                    <img :src="flags[index].flag" class="faq_flag" />
                                    <div v-show="!showTranslate" class="item_cont_title company_link">{{ item.nameEn }}</div>
                                    <div v-show="showTranslate" class="item_cont_title company_link">{{ item.name }}</div>
                                </div>
                                <div class="c_d_tag">
                                    <el-tag v-show="!showTranslate">{{ item.locationEn }}</el-tag>
                                    <el-tag v-show="showTranslate">{{ item.location }}</el-tag>
                                    <el-tag>{{ item.incDate }}</el-tag>
                                    <el-tag>{{ item.companyType }}</el-tag>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="index == 6">
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">Company type:</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.companyType }}</div>
                            </div>
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">Company form:</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.companyForm }}</div>
                            </div>
                        </div>
                        <div v-else-if="index == 7">
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">Years in business:</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.businessYears }}</div>
                            </div>
                        </div>
                        <div v-else-if="index == 8">
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">Number of shareholders:</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.sNumber }}</div>
                            </div>
                            <button class="btn_Shareholder" :disabled="contObj.sNumber == '0'" @click="viewMore">See detail shareholder strucutre</button>
                        </div>
                        <div v-else-if="index == 9">
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">Number of employees:</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.employees }}</div>
                            </div>
                        </div>
                        <div v-else-if="index == 10">
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">This company's revenue is about</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.revenue }}</div>
                            </div>
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">This company's financial status is</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.financialStatus }}</div>
                            </div>
                            <button class="btn_Shareholder" @click="findFinancial">See detail financial analysis</button>
                        </div>
                        <div v-else-if="index == 11">
                            <div class="item_cont_txt">{{ contObj.whether1 }}</div>
                            <button class="btn_Shareholder" :disabled="contObj.whether1 == 'No'" @click="findTrade">Find out more trade information</button>
                        </div>
                        <div v-else-if="index == 12">
                            <div class="item_cont_txt">{{ contObj.whether2 }}</div>
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">Stock symbol:</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.stockSymbol }}</div>
                            </div>
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">Stock exchange:</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.stockExchange }}</div>
                            </div>
                            <div class="btn_more" @click="viewMorePub">View more</div>
                        </div>
                        <div v-else-if="index == 13">
                            <div class="item_cont_txt">{{ contObj.whether3 }}</div>
                        </div>
                        <div v-else-if="index == 14">
                            <div class="item_cont_txt">{{ contObj.whether4 }}</div>
                        </div>
                        <div v-else-if="index == 15">
                            <div class="item_cont_txt">{{ contObj.creditRate ? contObj.creditRate.whether : '' }}</div>
                            <button class="btn_find" :disabled="contObj.creditRate ? contObj.creditRate.whether == 'No' : 'true'" @click="viewCredit">View Credit Rating</button>
                        </div>
                        <div v-else-if="index == 16">
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">Country:</div>
                                <div @click="gotocountry1()" style="cursor: pointer" class="item_cont_txt c_m_l">{{ contObj.country }}</div>
                            </div>
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">Capital:</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.capital }}</div>
                            </div>
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">Flag:</div>
                                <img @click="gotocountry1()" :src="contObj.flag" style="height: 15px; width: 25px; margin-top: 5px; cursor: pointer" class="faq_flag c_m_l" />
                            </div>
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">Currency:</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.currency }}</div>
                            </div>
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">Exchange rate:</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.exchangeRate }}</div>
                            </div>
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">Language:</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.language }}</div>
                            </div>
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">Population:</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.population  }}</div>
                            </div>
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">GDP Rank:</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.gdpRank }}</div>
                            </div>
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">GDP per capita:</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.gdpPerCapita  }}</div>
                            </div>
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">Total Volume of Trade:</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.totalTrade }}</div>
                            </div>
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">Country's Rating:</div>
                                <div class="item_cont_txt c_m_l">{{ contObj.countryRating }}</div>
                            </div>
                            <div class="flex_box c_m_b">
                                <div class="item_cont_title">Business Environment:</div>
                                <!-- <div class="item_cont_txt c_m_l">{{ contObj.bEnvironment }}</div> -->
                                <div class="bEnvironment c_m_l">
                                    <span style="background: rgb(238, 36, 36)"></span>
                                    <span style="background: rgb(247, 144, 36)"></span>
                                    <span style="background: rgb(255, 222, 42)"></span>
                                    <span style="background: rgb(135, 197, 71)"></span>
                                    <span style="background: rgb(19, 133, 70)"></span>
                                </div>
                                <img v-if="contObj.bEnvironment == '5'" style="position: relative; left: -20px; top: 22px; height: 14px" src="@/assets/img/shangshen.png" alt="" />
                                <img v-if="contObj.bEnvironment == '4'" style="position: relative; left: -47px; top: 22px; height: 14px" src="@/assets/img/shangshen.png" alt="" />
                                <img v-if="contObj.bEnvironment == '3'" style="position: relative; left: -75px; top: 22px; height: 14px" src="@/assets/img/shangshen.png" alt="" />
                                <img v-if="contObj.bEnvironment == '2'" style="position: relative; left: -105px; top: 22px; height: 14px" src="@/assets/img/shangshen.png" alt="" />
                                <img v-if="contObj.bEnvironment == '1'" style="position: relative; left: -132px; top: 22px; height: 14px" src="@/assets/img/shangshen.png" alt="" />
                            </div>
                            <div class="btn_more" @click="goCountry">View more</div>
                        </div>
                        <div v-else-if="index == 17">
                            <div class="item_cont_txt">{{ contObj.qDefault }}</div>
                            <div class="btn_see" @click="findContacts">See more information about verification service</div>
                        </div>
                        <div v-else-if="index == 18">
                            <div class="item_cont_txt">{{ contObj.qDefault }}</div>
                            <div class="btn_see" @click="findContacts">See more information about this company</div>
                        </div>
                        <div v-else-if="index == 19">
                            <div class="item_cont_txt">{{ contObj.qDefault }}</div>
                            <div class="btn_see" @click="findContacts">See more information about verification service</div>
                        </div>
                        <!-- <div v-for="(value, key) in item" :key="key">
                            <div>
                                <div class="item_cont_title">{{ key }}</div>
                                <div class="item_cont_txt">{{ value }}</div>
                            </div>
                        </div> -->
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>

        <el-dialog :visible.sync="creditDialog" center :close-on-click-modal="false" custom-class="dialog_lg " :append-to-body="true">
            <div class="dialog_att_cont">
                <div class="dia_title">CREDIT ASSESSMENT</div>
                <div class="table_box">
                    <span class="aaa">High Risk</span>
                    <span class="bbb">Medium</span>
                    <span class="ccc">Moderate</span>
                    <span class="ddd">Average</span>
                    <span class="fff">Lower Than Avg.</span>
                    <span class="ggg">Low Risk</span>
                    <div class="echarts" ref="riskRef"></div>
                    <el-card class="box-card computer_right_cont_box">
                        <div v-for="(item, index) in cardlist" :key="index" class="text item">
                            <div :style="[bc, { backgroundColor: item.class }]">
                                <img style="height: 20px; width: 20px" :src="item.icon" alt="" />
                            </div>

                            <div class="card-text">
                                <span class="text-head">{{ item.title }}</span>
                                <h2 class="text-foot">{{ item.text }}</h2>
                            </div>
                        </div>
                    </el-card>
                </div>
            </div>
        </el-dialog>

        <!-- Attention 弹窗 -->
        <el-dialog :visible.sync="attentionDialog" center :close-on-click-modal="false" custom-class="dialog_normal" :append-to-body="true">
            <div class="dialog_att_cont">
                <div class="dia_title">Attention</div>
                <el-form :model="attentionForm" ref="attentionForm">
                    <div class="dia_cont_txt">Do you want to order this company details information?</div>
                    <el-form-item class="dia_item">
                        <div class="flex_center_between_box">
                            <label class="dia_item_lab">My Balance:</label>
                            <div>
                                <el-radio v-model="attentionForm.balance" class="dia_radio" label="1">50 paid credits</el-radio>
                                <el-radio v-model="attentionForm.balance" class="dia_radio" label="2">2 free credits</el-radio>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item class="dia_item">
                        <div class="flex_center_between_box">
                            <label class="dia_item_lab">Order:</label>
                            <div class="dia_item_txt">-1 Companies</div>
                        </div>
                    </el-form-item>
                    <el-form-item class="dia_btn">
                        <div class="flex_center_between_box">
                            <el-button class="btn_form_sub btn_blue" @click="submit()">Yes</el-button>
                            <el-button class="btn_form_sub btn_cancel" @click="attentionDialog = false">No</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="false" @close="closeDialog" custom-class="dialog_normalsuo" :append-to-body="true">
            <div>
                <!-- <div class="dialog_detail_title">Pay attention to</div> -->
                <div class="detail_cont">
                    <div class="detail_cont">
                        <div class="detail_cont_text" v-if="code == '12'">• Import & export information is a paid feature, this will cost 2 units.</div>
                        <div class="detail_cont_text" v-else-if="false">• This is a paid feature, this will allow you to view all the available details including financial details and credit assessment on the company. Meanwhile, a fresh updated report will be ordered. This will cost 10 units.</div>
                        <div class="detail_cont_text" v-else>• This is a paid feature, open it to view all the available online information about this company. This will cost 1 unit.</div>

                        <!-- <div>• Shareholder penetration is a paid feature, this will cost 2 units.</div> -->
                    </div>

                    <!-- <div>• Shareholder penetration is a paid feature, this will cost 2 units.</div> -->
                </div>
            </div>
            <div class="flex_center_between_box">
                <el-button class="btn_export" @click="onSubmitkoudian()">OK</el-button>
                <el-button class="btn_export" @click="onSubmitquxiao()">Buy credits</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getAnswerList, getQuestionList, ordersAdd, getFinancials } from '@/api/companyApi.js';
import crypto from '@/utils/crypto';
import { mixins1 } from '@/mixins/index';
export default {
    mixins: [mixins1],
    data() {
        return {
            activeNames: [],
            activeNamesone: [],
            faqList: [],
            faqSortList: [],
            attentionDialog: false, //弹框
            attentionForm: { balance: '' },
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            contObj: {},
            code: '',
            collFaqList: [],
            creditDialog: false,
            flags: [],

            cardlist: [
                {
                    icon: require('../../assets/img/company/LIqui.png'),
                    title: 'Local Credit Score ',
                    text: '',
                    class: '#F56B6BFF',
                },
                {
                    icon: require('../../assets/img/company/finan.png'),
                    title: 'Possibility Default (PD)',
                    text: '',
                    class: '#FF7600FF',
                },
                {
                    icon: require('../../assets/img/company/crea.png'),
                    title: 'Solvency index (SI)',
                    text: '',
                    class: '#1290C9FF',
                },
                {
                    icon: require('../../assets/img/company/risk.png'),
                    title: 'Credit Line',
                    text: '',
                    class: '#55B419FF',
                },
            ],
            bc: {
                height: '40px',
                width: '40px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            untia: '',
            creditOpinion: 'D',
            creditScore: '0',
            newDialog: false,
            token: sessionStorage.getItem('token'),
        };
    },
    computed: {
        //index传值 是否翻译
        showTranslate: {
            get() {
                return this.$parent.indexIsTranslate;
            },
            set() {},
        },
        curPagePower: {
            get() {
                return JSON.parse(this.$parent.powerToPage);
            },
            set() {},
        },
    },
    created() {
        this.getQuestionData();
    },
    mounted() {
        // window.addEventListener('setItem', this.listenMenuIndexChange);
        window.addEventListener('setItem', () => {
            // this.isfalow=JSON.parse(sessionStorage.getItem('company')).isFavorite
            this.token = sessionStorage.getItem('token');
            if (this.token) {
                this.faqList = [];
                this.getQuestionData();
                // 4.27
                // this.getCollData();
                // this.hasPdfPurchase();
            }
        });
    },

    methods: {
        gotocountry1(row) {
            let countryCode = this.$route.query.companyCountry;
            if (countryCode != 'n.a.') {
                let routeData = this.$router.resolve({
                    path: '/country?' + '&companyCountry=' + countryCode,
                });

                window.open(routeData.href, '_blank');
            }
            //   if(!row.countryCode.includes(';')){
            //   }
        },
        // 扣除积分提醒
        closeDialog() {
            this.newDialog = false;
            this.code = '';
        },
        onSubmitquxiao() {
            // this.newDialog = false;
            // this.$emit('closeCloseDialog', false);
            let that = this;
            let userinfo = JSON.parse(sessionStorage.getItem('userInfo')).userInfo;

            console.log(`userinfo`, userinfo);
            let routeData = '';
            setTimeout(function () {
                if (userinfo.type == '3') {
                    routeData = that.$router.resolve({
                        path: '/account/quota',
                    });
                } else {
                    routeData = that.$router.resolve({
                        path: '/account/addCredit',
                    });
                }

                window.open(routeData.href, '_blank');
            }, 1000);
        },

        async getQuestionData() {
            const id3a = this.$route.query.id3a;
            // const companyCountry = this.$route.query.companyCountry
            const base = JSON.parse(sessionStorage.getItem('base'));
            let params = {
                aaaId: id3a,
                countryCode: base.base.countryFlag,
                page: this.page.pageNo,
                pageSize: this.page.pageSize,
                type: 1,
                operatCode: base.base.operatCode,
                entclasscode: base.base.entclasscode,
                isic: base.current.isicCode,
            };

            let result = await getQuestionList('id3a=' + params.aaaId + '&companyCountry=' + params.countryCode);

            let data = JSON.parse(crypto.decrypt(result));
            // let faqsList = [];
            if (data && data.code) {
                let sortData = data.data.sort(this.sortBy('sort'));
                //获取问题答案
                for (let i = 0; i < sortData.length; i++) {
                    this.faqList.push(sortData[i]);
                }
        
            }
        },

        sortBy(property) {
            return function (value1, value2) {
                let b = Number(value1[property]);
                let a = Number(value2[property]);
                return a < b ? 1 : a > b ? -1 : 0;
            };
        },
        // 问题集展开
        changeCollapse() {
            this.contObj = {};
            console.log(this.faqList);
            // console.log(this.activeNames=[]);
            let power = this.faqList[this.activeNames - 1] ? this.faqList[this.activeNames - 1].power : 'undefined';
            console.log(this.faqList[this.activeNames - 1]);
            this.activeNamesone = this.activeNames;
            let arr = JSON.parse(JSON.stringify(this.collFaqList));
            console.log(arr);
            this.contObj = {};
            // return
            if (!power) {
                this.activeNames = [];
                this.newDialog = true;
                // let arr=JSON.parse(JSON.stringify(this.collFaqList))
                // this.getOrdersAdd(1).then(res => {
                //   if (res){
                //     let result = arr.filter(item=> {
                //       return item.num+1== this.activeNames
                //     })
                //     if(result.length>0){

                //         this.contObj=result[0].content
                //         console.log(`this.contObj`, this.contObj);
                //       }
                //       else{
                //         this.clickFaq()
                //       }
                //     }
                // });
            } else if (power !== 'undefined') {
                let result = arr.filter(item => {
                    return item.num + 1 == this.activeNames;
                });
                if (result.length > 0) {
                    this.contObj = result[0].content;
                    console.log(`this.contObj`, this.contObj);
                } else {
                    this.clickFaq();
                }
            }
        },
        clickFaq() {
            const id3a = this.$route.query.id3a;
            const base = JSON.parse(sessionStorage.getItem('base'));
            // console.log(`base`, base);
            let params = {
                aaaId: id3a,
                countryCode: base.base.countryFlag,
                page: this.page.pageNo,
                pageSize: this.page.pageSize,
                // type: this.activeNames,
                operatCode: base.base.operatCode,
                entclasscode: base.base.entclasscode,
                isic: base.current.isicCode,
            };
            let type = this.activeNames;
            this.contObj = {};
            //第几个问题
            getAnswerList('aaaId=' + params.aaaId + '&countryCode=' + params.countryCode + '&page=' + params.page + '&pageSize=' + params.pageSize + '&type=' + type + '&operatCode=' + params.operatCode + '&entclasscode=' + params.entclasscode + '&isic=' + params.isic).then(answerList => {
                let answerPaseResult = JSON.parse(crypto.decrypt(answerList));
                console.log(`answerPaseResult`, answerPaseResult);
                //  console.log(`answerPaseResult`, answerPaseResult);
                if (answerPaseResult && answerPaseResult.code && answerPaseResult.data) {
                    this.contObj = JSON.parse(JSON.stringify(answerPaseResult.data));
                    if (type == 17) {
                        this.contObj = JSON.parse(JSON.stringify(answerPaseResult.data)).countryInfo;
                        // console.log(`this.contObj`, this.contObj);
                        let countryImg = this.getCountryImgUrl(this.$route.query.companyCountry);
                        this.contObj.flag = require('../../../static' + countryImg);
                    }
                    if (type == 6) {
                        let list = answerPaseResult.data.similarCompany;
                        let arr = [];
                        list.map(item => {
                            let countryImg = this.getCountryImgUrl(item.country);
                            let obj = {
                                flag: require('../../../static' + countryImg),
                            };
                            arr.push(obj);
                        });
                        this.flags = arr;
                    }
                    let obj = {
                        num: type - 1,
                        content: this.contObj,
                    };
                  
                    this.collFaqList.push(obj);
                    console.log(this.collFaqList);
                  
                }
            });
        },

        findContacts() {
            this.$emit('updateSubLink', 0);
            this.$router.push({
                path: '/company/companysnapshot?id3a=' + this.$route.query.id3a + '&companyCountry=' + this.$route.query.companyCountry,
            });
        },
        viewMore() {
            this.$emit('updateSubLink', 3);
            this.$router.push({
                path: '/company/ownership?id3a=' + this.$route.query.id3a + '&companyCountry=' + this.$route.query.companyCountry,
            });
        },
        contactInfo() {
            this.$emit('updateSubLink', 2);
            this.$router.push({
                path: '/company/contactinfo?id3a=' + this.$route.query.id3a + '&companyCountry=' + this.$route.query.companyCountry,
            });
        },
        viewMorePub() {
            this.newDialog = true;
            this.$emit('updateSubLink', 4);
            this.$router.push({
                path: '/company/businessoperations?id3a=' + this.$route.query.id3a + '&companyCountry=' + this.$route.query.companyCountry,
            });
        },
        findTrade() {
            console.log(`this.pa`, this.$parent.menuTitleList);
            if (this.$parent.menuTitleList[6].power) {
                this.$emit('updateSubLink', 6);
                this.$router.push({
                    path: '/company/import&export?id3a=' + this.$route.query.id3a + '&companyCountry=' + this.$route.query.companyCountry,
                });
            } else {
                this.newDialog = true;
                this.code = this.faqList[this.activeNames - 1]?.code ? this.faqList[this.activeNames - 1]?.code : '';
            }
        },
        onSubmitkoudian() {
            if (this.code != '12') {
                let arr = JSON.parse(JSON.stringify(this.collFaqList));
                console.log(this.collFaqList)
                let that = this;
                this.getOrdersAdd(1).then(res => {
                    if (res) {
                        this.newDialog = false;
                        this.activeNames = this.activeNamesone;
                        let result = arr.filter(item => {
                            return item.num + 1 == this.activeNames;
                        });
                        if (result.length > 0) {
                            this.contObj = result[0].content;
                            console.log(`this.contObj`, this.contObj);
                        } else {
                            that.clickFaq();
                        }
                        this.faqList = [];

                        this.getQuestionData();
                    } else {
                        this.newDialog = false;
                    }
                });
            } else {
                this.$parent.getOrdersAdd(13).then(res => {
                    if (res) {
                        // this.faqList=[]
                        this.newDialog = false;
                        this.$emit('updateSubLink', 6);
                        this.$router.push({
                            path: '/company/import&export?id3a=' + this.$route.query.id3a + '&companyCountry=' + this.$route.query.companyCountry,
                        });
                    } else {
                        this.newDialog = false;
                    }
                });
            }
        },

        findFinancial() {
            this.$emit('updateSubLink', 8);
            this.$router.push({
                path: '/company/performance?id3a=' + this.$route.query.id3a + '&companyCountry=' + this.$route.query.companyCountry,
            });
        },
        goCountry() {
            this.$router.push({
                path: '/country?companyCountry=' + this.$route.query.companyCountry,
            });
        },
        viewCredit() {
            this.creditDialog = true;
            this.getCreditCard();
        },
        /**
         *用户购买报告 "type": 1 线下，2 线上, 13 Import/Export
         */
        async getOrdersAdd(type) {
            const id3a = this.$route.query.id3a;
            const companyCountry = this.$route.query.companyCountry;
            const base = JSON.parse(sessionStorage.getItem('base'));
            //1  :线上报告
            let params = {
                companyName: base.base.nameEn, //必须
                aaaId: id3a, //必须
                companyCountry: companyCountry, //必须
                method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
                methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
                type: type, //订单类型 //必须
            };
            let status = '';
            await ordersAdd(params).then(result => {
                let data = result;
                if (data && data.code) {
                    // this.getQuestionData()
                    this.$parent.getMenuList();
                    status = true;
                    this.$message({
                        message: data.msg,
                        type: 'success',
                        // duration: 5000,
                    });
                } else {
                    this.$message({
                        message: data.msg,
                        type: 'error',
                    });
                    let that = this;
                    setTimeout(function () {
                        let routeData = that.$router.resolve({
                            path: '/account/addCredit',
                        });

                        window.open(routeData.href, '_blank');
                    }, 2000);
                    status = false;
                }
            });
            return status;
        },

        getCreditCard() {
            const params = {
                id3a: this.$route.query.id3a,
                companyCountry: this.$route.query.companyCountry,
            };
            getFinancials(params).then(res => {
                let data = JSON.parse(crypto.decrypt(res));
                // console.log(data);
                if (data.code) {
                    let { creditBO } = data.data;

                    this.cardlist.map((item, index) => {
                        if (item.title == 'Local Credit Score ') {
                            item.text = creditBO.creditScore;
                        }
                        if (item.title == 'Possibility Default (PD)') {
                            item.text = creditBO.pd;
                        }
                        if (item.title == 'Solvency index (SI)') {
                            item.text = creditBO.si;
                        }
                        if (item.title == 'Credit Line') {
                            item.text = creditBO.creditLine;
                        }
                    });
                    this.creditOpinion = creditBO.creditOpinion;
                    this.creditScore = creditBO.creditScore / 100;
                }

                this.$nextTick(() => {
                    this.riskEchart();
                });
            });
        },

        riskEchart() {
            const that = this;

            const option = {
                series: [
                    {
                        type: 'gauge',
                        startAngle: 180,
                        endAngle: 0,

                        center: ['50%', '65%'],
                        radius: '90%',
                        // radius: '145%',
                        clockwise: true,
                        show: true,
                        min: 0,
                        max: 1,
                        splitNumber: 5,

                        axisLine: {
                            roundCap: true,
                            lineStyle: {
                                width: 20,

                                color: [
                                    [
                                        0.25,
                                        {
                                            type: 'linear',

                                            x: 0,

                                            y: 0,

                                            x2: 0,

                                            y2: 1,

                                            colorStops: [
                                                {
                                                    offset: 1,
                                                    color: '#F56B6BFF',
                                                },
                                            ],

                                            global: false,
                                        },
                                    ],

                                    [
                                        0.5,
                                        {
                                            type: 'linear',

                                            x: 0,

                                            y: 0,

                                            x2: 0,

                                            y2: 1,

                                            colorStops: [
                                                {
                                                    offset: 0,
                                                    color: '#FFDC17FF',
                                                },
                                                {
                                                    offset: 1,
                                                    color: '#FA6666FF',
                                                },
                                            ],

                                            global: false,
                                        },
                                    ],

                                    [
                                        1,
                                        {
                                            type: 'linear',

                                            x: 0,

                                            y: 0,

                                            x2: 0,

                                            y2: 1,

                                            colorStops: [
                                                {
                                                    offset: 0,
                                                    color: '#FFDC17FF',
                                                },
                                                {
                                                    offset: 1,
                                                    color: '#55B419FF',
                                                },
                                            ],

                                            global: false,
                                        },
                                    ],
                                ],
                            },
                        },

                        pointer: {
                            icon: 'circle',

                            length: '20%',
                            width: 30,
                            offsetCenter: [0, '-82%'],
                            itemStyle: {
                                color: 'auto',
                            },
                        },
                        axisTick: {
                            length: 5,
                            distance: -28,
                            lineStyle: {
                                color: 'auto',
                                width: 2,
                            },
                        },
                        // 刻度线
                        splitLine: {
                            show: false,
                            length: 10,
                            // distance:-20,
                            lineStyle: {
                                color: 'auto',
                                width: 8,
                            },
                        },
                        axisLabel: {
                            show: false,
                            rotate: 'tangential',
                            fontWeight: 'normal',
                            fontSize: 0,
                            distance: -62,
                            width: 70,
                            height: 40,
                            fontFamily: 'Arial',
                            overflow: 'truncate',
                            color: [
                                [
                                    0.25,
                                    {
                                        type: 'linear',

                                        x: 0,

                                        y: 10,

                                        x2: 30,

                                        y2: 11,

                                        colorStops: [
                                            {
                                                offset: 1,
                                                color: '#F56B6BFF',
                                            },
                                        ],

                                        global: false,
                                    },
                                ],

                                [
                                    0.5,
                                    {
                                        type: 'linear',

                                        x: 0,

                                        y: 0,

                                        x2: 0,

                                        y2: 1,

                                        colorStops: [
                                            {
                                                offset: 0,
                                                color: '#FFDC17FF',
                                            },
                                            {
                                                offset: 1,
                                                color: '#FA6666FF',
                                            },
                                        ],

                                        global: false,
                                    },
                                ],

                                [
                                    1,
                                    {
                                        type: 'linear',

                                        x: 0,

                                        y: 0,

                                        x2: 0,

                                        y2: 1,

                                        colorStops: [
                                            {
                                                offset: 0,
                                                color: '#FFDC17FF',
                                            },
                                            {
                                                offset: 1,
                                                color: '#55B419FF',
                                            },
                                        ],

                                        global: false,
                                    },
                                ],
                            ],

                            formatter: function (value) {
                                if (value === 1) {
                                    return 'Low Risk';
                                } else if (value === 0.8) {
                                    return 'Lower Than Avg.';
                                } else if (value === 0.6) {
                                    return 'Average';
                                } else if (value === 0.4) {
                                    return 'Moderate';
                                } else if (value === 0.2) {
                                    return 'Medium';
                                } else if (value === 0.0) {
                                    return 'High Risk';
                                }
                                return '';
                            },
                        },

                        title: {
                            offsetCenter: [0, '20%'],
                            fontSize: 15,
                            color: '#ccc',
                        },
                        // 仪表盘数字
                        detail: {
                            lineHeight: 126,
                            fontSize: 45,
                            offsetCenter: [1, '-30'],
                            valueAnimation: true,

                            borderColor: '#55B419FF',

                            width: 200,
                            formatter: function (value) {
                                var a = 'd';
                                if (Math.round(value * 100)) {
                                    a = 'A';
                                }
                                return that.creditOpinion;
                            },
                            color: '#55B419FF',
                        },
                        data: [
                            {
                                value: that.creditScore,
                                name: 'Credit Opinion',
                            },
                        ],
                    },
                    {
                        type: 'gauge',
                        startAngle: 180,
                        endAngle: 0,
                        center: ['50%', '65%'],
                        radius: '59%',
                        min: 0,
                        max: 1,
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                width: 1,
                                color: [
                                    [
                                        0.5,
                                        {
                                            type: 'linear',

                                            x: 0,

                                            y: 0,

                                            x2: 0,

                                            y2: 1,

                                            colorStops: [
                                                {
                                                    offset: 0,
                                                    color: '#FFDC17FF',
                                                },
                                                {
                                                    offset: 1,
                                                    color: '#FA6666FF',
                                                },
                                            ],

                                            global: false,
                                        },
                                    ],

                                    [
                                        1,
                                        {
                                            type: 'linear',

                                            x: 0,

                                            y: 0,

                                            x2: 0,

                                            y2: 1,

                                            colorStops: [
                                                {
                                                    offset: 0,
                                                    color: '#FFDC17FF',
                                                },
                                                {
                                                    offset: 1,
                                                    color: '#55B419FF',
                                                },
                                            ],

                                            global: false,
                                        },
                                    ],
                                ],
                            },
                        },
                        pointer: {
                            icon: 'triangle',
                            length: '12%',
                            width: 20,
                            offsetCenter: [0, '-105%'],
                            itemStyle: {
                                color: '#55B419FF',
                            },
                        },

                        data: [
                            {
                                value: that.creditScore,
                                detail: {
                                    show: false,
                                },
                            },
                        ],
                    },
                ],
            };
            this.riskChart = this.$echarts.init(this.$refs.riskRef);
            this.riskChart.setOption(option);
        },
    },
};
</script>

<style scoped>
.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}
.title_txt img {
    width: 20px;
    margin-right: 8px;
}
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}

.faq_cont {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
    padding: 5px 30px;
}

.title {
    font-family: 'Arial Bold';
    margin-top: 20px;
    margin-bottom: 20px;
}

.faq_cont .el-collapse {
    border: none;
    font-family: 'Arial';
}

.item_title /deep/ .el-collapse-item__header {
    font-size: 16px;
    color: #022955;
    font-family: 'Arial';
    margin: 6px 0;
    border-color: #e7eaed;
    height: auto !important;
}
.item_title.el-collapse-item.is-active /deep/ .el-collapse-item__wrap {
    background-color: #ebf2ff;
}
.item_title.el-collapse-item.is-active:last-child /deep/ .el-collapse-item__wrap,
.item_title:last-child /deep/ .el-collapse-item__header {
    border-width: 0;
}

.item_title /deep/ .el-collapse-item__header.is-active {
    border-bottom-color: transparent;
}

.item_cont_title {
    /* font-family: "Arial Bold"; */
    font-family: 'Arial';
    /* margin-bottom: 10px; */
    color: #022955;
}

.item_cont_txt {
    font-size: 14px;
    color: #666666;
    /* background: #e7eaed; */
    /* opacity: 0.5; */
    margin-bottom: 20px;
}

.item_title /deep/.el-collapse-item__content {
    padding-bottom: 0;
}

.item_title /deep/ .el-collapse-item__arrow {
    width: 24px;
    height: 24px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e7eaed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 9px;
}

.item_title /deep/ .el-collapse-item__arrow::before {
    content: '';
}

.item_title /deep/ .el-collapse-item__arrow {
    background-image: url(../../assets/img/search/menu_ic1.png);
}
.item_title1 /deep/ .el-collapse-item__arrow {
    background-image: url(../../assets/img/search/menu_ic1.png);
    display: none;
}
.item_title /deep/ .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
    background-image: url(../../assets/img/search/menu_ic2.png);
}
/* .statesuo /deep/ .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
    background-image: url(../../assets/img/search/menu_ic2.png);
} */

.c_m_l {
    margin-left: 20px;
    margin-bottom: 0;
}

.c_m_b {
    margin-bottom: 10px;
    /* background: #e7eaed; */
}

.faq_flag {
    width: 52px;
    height: 36px;
    margin-right: 15px;
    box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
}

.company_link:hover {
    margin-bottom: 0;
}

.company_link:hover {
    color: #1290c9;
    cursor: pointer;
}
.btn_more,
.btn_Shareholder,
.btn_find,
.btn_see {
    float: right;
    width: 356px;
    height: 40px;
    line-height: 40px;
    margin-bottom: 20px;
    background: #edf9ff;
    border-radius: 12px;
    border: 1px solid #1290c9;
    font-size: 16px;
    color: #1290c9;
    text-align: center;
    cursor: pointer;
}
.btn_find {
    width: 180px;
}
.btn_Shareholder {
    width: 250px;
}
.btn_more {
    width: 160px;
}
.dialog_att_cont {
    padding: 20px 20px 10px;
}

.dia_title {
    font-size: 20px;
    font-family: 'Arial Bold';
    line-height: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #022955;
}

.dia_cont_txt {
    font-size: 16px;
    margin-bottom: 50px;
}

.dia_item {
    font-size: 16px;
    /* padding-bottom: 22px; */
    border-bottom: 1px solid #e7eaed;
    margin-bottom: 10px;
}

.dia_item_lab {
    color: #8497ab;
    font-size: 16px;
}

.dia_radio /deep/ .el-radio__inner {
    width: 24px;
    height: 24px;
    background: #ffffff;
    border: 1px solid #e7eaed;
}

.dia_radio /deep/ .el-radio__input.is-checked .el-radio__inner {
    border-color: #1290c9;
}

.dia_radio /deep/ .el-radio__inner::after {
    width: 14px;
    height: 14px;
    background: #1290c9;
}

.dia_item_txt,
.dia_radio /deep/ .el-radio__input.is-checked + .el-radio__label {
    color: #022955;
}

.dia_btn {
    margin-top: 30px;
}

.btn_form_sub {
    width: 100px;
    height: 40px;
    font-size: 16px;
    border-radius: 12px;
    border: none;
}

.btn_blue {
    background: #1290c9;
    color: #ffffff;
}

.btn_cancel {
    background: #e7eaed;
    color: #022955;
}
.c_d_tag .el-tag {
    height: 26px;
    line-height: 26px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #ff7600;
    font-size: 12px;
    color: #ff7600;
    margin: 6px;
}
.bEnvironment span {
    display: inline-flex;
    width: 28px;
    height: 15px;
}
</style>

<style scoped lang="less">
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}

.container {
    // background: #ffffff;
    // box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    // border-radius: 14px;
    // padding: 20px 17px;
    margin-top: 69px;
}

.zhongjian {
    display: flex;
    justify-content: center;
}

.td-width-span {
    flex: 1;
}

.td-width-txt {
    display: flex;
    justify-content: center;
}

.update {
    width: 90px;
    height: 30px;
    background: #1290c9;
    border-radius: 8px;
    color: white;
    position: relative;
    top: 73px;
    right: 20px;
}

.title {
    display: flex;
    align-items: center;

    justify-content: space-between;

    .title-left {
        display: flex;
        font-size: 16px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #022955;
    }
}

.table {
    margin-top: 71px;
}

.el-card.is-always-shadow {
    box-shadow: none;
    padding-top: 20px;
}

// 卡片
/deep/ .el-card {
    // border: 1px solid #EBEEF5;
    background-color: #fff;
    color: #303133;
    transition: 0.3s;
    border: none;

    .el-card__body {
        padding: 3px;
        display: flex;
        flex-wrap: wrap;
        width: 508px;
        justify-content: space-between;

        .item {
            display: flex;
            padding: 15px 35px 15px 20px;

            height: 70px;
            background: #ffffff;
            box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
            border-radius: 14px;
            margin: 0 0px 20px 0;

            .card-text {
                margin-left: 10px;
                display: flex;
                width: 135px;
                flex-wrap: wrap;

                .text-head {
                    width: 135px;
                    height: 16px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #8497ab;
                }

                .text-foot {
                    width: 110px;
                    height: 18px;
                    font-size: 12px;
                    font-family: Arial-BoldMT, Arial;
                    font-weight: normal;
                    color: #022955;
                    margin-top: 8px;
                }
            }
        }
    }
}

.td {
    margin: 15px 0 0 25px;
}

// 表格

.table_box {
    display: flex;
    justify-content: space-between;
    position: relative;

    .echarts {
        width: 420px;
        height: 260px;
    }
}
.aaa {
    font-size: 10px;
    color: #fa6666;
    position: absolute;
    left: 30px;
    top: 164px;
}
.bbb {
    font-size: 10px;
    color: #fa755c;
    position: absolute;
    left: 52px;
    top: 84px;
}
.ccc {
    font-size: 10px;
    color: #fc9249;
    position: absolute;
    left: 110px;
    top: 34px;
}
.ddd {
    font-size: 10px;
    color: #ffd81a;
    position: absolute;
    left: 258px;
    top: 34px;
}
.fff {
    font-size: 10px;
    color: #aec918;
    position: absolute;
    left: 325px;
    // width: 85px;
    height: 30px;
    top: 84px;
}
.ggg {
    font-size: 10px;
    color: #63b819;
    position: absolute;
    left: 345px;

    top: 164px;
}
.hedr-icon-ydong {
    position: absolute;

    font-weight: 600;
    right: 12px;
}
/* .left_menu /deep/ .el-collapse-item__arrow{
display: none;
} */
.item_title /deep/ .el-collapse-item__header {
    position: relative;
}
.detail_cont {
    padding: 0px 10px 10px;
}
.detail_cont_text {
    font-size: 14px;
    color: #022955;
}

@media (max-width: 821px) {
.item_title /deep/ .el-collapse-item__header {
    font-size: 14px !important;
}
.container{
    margin-top: 20px !important;
}

.faq_cont{
    padding: 5px 15px !important;
}
.dialog_att_cont {
    padding:0px !important;
}
.aaa{
    left:0px !important ;
}
.bbb{
      left:22px !important ;
}
.ccc{
    left:80px !important ;
}
.ddd{
     left:228px !important ;
}
.fff{
    left:295px !important ;
}
.ggg{
    left:320px !important ;
}
 .btn_see{
  width: 310px !important;
  font-size: 12px !important;
 }
  .btn_more ,.btn_Shareholder,.btn_find {
     font-size: 12px !important;
 }
 .item_cont_title,.item_cont_txt{
    font-size: 12px !important;
 }
 
}
</style>
